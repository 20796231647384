/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code"
  }, _provideComponents(), props.components), {Type, ParamTable, Params} = _components;
  if (!ParamTable) _missingMdxReference("ParamTable", true);
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Paginationは、ページネーションに必要な要素を生成し、管理するためのコンポーネントです。"), "\n", React.createElement(_components.h2, {
    id: "プロパティ",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3",
    "aria-label": "プロパティ permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "プロパティ"), "\n", React.createElement(_components.h3, {
    id: "items",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#items",
    "aria-label": "items permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "items")), "\n", React.createElement(Type, {
    code: 'items:PaginationItem[]'
  }), "\n", React.createElement(_components.p, null, "それぞれのページに対応するデータの入った配列です。各データは、以下のプロパティをもっています。"), "\n", React.createElement(ParamTable, {
    hasType: true,
    params: [['li', 'HTMLLIElement', 'LI要素で、ボタンの親にあたる'], ['button', 'HTMLButtonElement', '各ページ用のボタン'], ['page', 'number', '対応するページインデックス']]
  }), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "getat",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getat",
    "aria-label": "getat permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getAt()")), "\n", React.createElement(Type, {
    code: 'getAt( index: number ): PaginationItem'
  }), "\n", React.createElement(_components.p, null, "与えられたスライドインデックスに対応するページネーションのデータを返します。このメソッドはインデックスをページインデックスに変換するため、異なるインデックスが同じデータを返す可能性があります。"), "\n", React.createElement(_components.p, null, "たとえば、", React.createElement(_components.code, null, "perPage"), "オプションが", React.createElement(_components.code, null, "3"), "の場合、", React.createElement(_components.code, null, "getAt( 0 )"), "、", React.createElement(_components.code, null, "getAt( 1 )"), "および", React.createElement(_components.code, null, "getAt( 2 )"), "はすべて同じ結果を返します。"), "\n", React.createElement(Params, {
    params: [['index', 'スライドインデックス']],
    ret: '該当するデータがある場合は、`PaginationItem`オブジェクトを、ない場合は`undefined`を返す'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
